//    Copyright 2019 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React from 'react';

import Hidden from "@material-ui/core/Hidden";
import IconButton from '@material-ui/core/IconButton';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';

import {ONLY_LARGE, ONLY_SMALL} from "../constants";

import './HeaderMenu.scss';
import useSidebar from "../state/useSidebar";

const HeaderMenu: React.FC = () => {
  const setShowSidebar = useSidebar(state => state.setShowSidebar);

  return <div className="header-menu">
    <Hidden only={ONLY_SMALL}>
      <a href="/logout">
        <ExitToAppIcon/>
      </a>
    </Hidden>
    <Hidden only={ONLY_LARGE}>
      <IconButton color="inherit" onClick={() => setShowSidebar(true)}>
        <MenuIcon/>
      </IconButton>
    </Hidden>
  </div>;
}

export default HeaderMenu;