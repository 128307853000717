//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import BaseController from "./BaseController";
import {getJson} from "../../helpers";
import {MonthlySummary} from "../../types/summary";

class SummaryController extends BaseController<MonthlySummary> {
  constructor() {
    super("user/summary");

    this.get = this.get.bind(this);
  }

  makeFromJson(o: any): MonthlySummary {
    return {
      id: -1,
      month: o.month,
      result: o.result,
    }
  }

  async get(): Promise<MonthlySummary> {
    return this.makeFromJson(await getJson(this.apiUrl));
  }
}

export default new SummaryController();
