//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import {List} from "immutable";

import BaseController from "./BaseController";
import {Transaction} from "../../types/transactions";
import {getJson, postJson} from "../../helpers";
import {Tag} from "../../types/tags";

class TransactionController extends BaseController<Transaction> {
  constructor() {
    super("transaction");

    this.getUnassignedTransactions = this.getUnassignedTransactions.bind(this);
  }

  makeFromJson(o: any): Transaction {
    return {
      amount: o.amount,
      subject: o.subject,
      date: new Date(o.date),
      account: o.account,
      recipient: o.recipient,
      id: o.id,
    };
  }

  async getForAccount(accountId: number): Promise<List<Transaction>> {
    const url = new URL(this.apiUrl);
    url.searchParams.set("account_id", String(accountId));

    const result: [] = await getJson(url.toString());

    return List(result.map(t => this.makeFromJson(t)));
  }

  async updateTags(transaction: Transaction, tags: List<Tag>): Promise<List<Tag>> {
    const url = '/api/v1/tag/frontendsync';
    const data = {
      transaction: transaction.id,
      tags: tags.map(t => t.id).toArray(),
    }

    await postJson(url, data);

    return tags;
  }

  async getUnassignedTransactions(): Promise<List<Transaction>> {
    const result: [] = await getJson('/api/v1/transaction/withouttag');
    return List(result.map(t => this.makeFromJson(t)));
  }
}

export default new TransactionController();
