//    Copyright 2021 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import {List} from "immutable";
import {useQuery} from "react-query";

import {Account} from "../types/accounts";
import {Transaction} from "../types/transactions";

import TransactionController from "../classes/controller/TransactionController";

import {QUERY_KEYS} from "../constants";

export default function useTransactionsByAccount(account: Account): List<Transaction> | undefined {
  const {data} = useQuery([QUERY_KEYS.transactions, account.id],
    () => TransactionController.getForAccount(account.id),
    {
      staleTime: Infinity
  });

  return data;
}