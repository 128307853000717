//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import {List} from "immutable";
import {Datum} from "@nivo/line";

import BaseController from "./BaseController";
import {Account, AccountState} from "../../types/accounts";
import {getJson} from "../../helpers";

class AccountController extends BaseController<Account> {
  constructor() {
    super("account");

    this.getAll = this.getAll.bind(this);
    this.getBalanceHistory = this.getBalanceHistory.bind(this);
  }

  makeFromJson(o: any): Account {
    return {
      id: o.id,
      name: o.name,
      iban: o.iban,
      balance: o.balance,
      favorite: o.favorite,
      hidden: o.hidden,
    };
  }

  public async getAll(): Promise<List<Account>> {
    // TODO use better url here!
    const url = '/api/v1/user/accounts';
    const result: AccountState = await getJson(url);

    return List(result.accounts.map(a => this.makeFromJson(a)));
  }

  public async getBalanceHistory(accountId: number): Promise<Datum[]> {
    const url = this.makeObjectUrl({id: accountId}, "balancehistory");
    return await getJson(url) as Datum[];
  }
}

export default new AccountController();
