//    Copyright 2021 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import {useQuery} from "react-query";
import {QUERY_KEYS} from "../constants";
import SystemInfoController from "../classes/controller/SystemInfoController";

export default function useSystemInfo() {
  return useQuery(QUERY_KEYS.systemInfo, SystemInfoController.get, {
    staleTime: Infinity
  });
}
