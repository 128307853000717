//    Copyright 2021 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import {useMutation, useQueryClient} from "react-query";
import {List, Map} from "immutable";
import {Tag, TagsByTransaction, TransactionId} from "../types/tags";
import {Transaction} from "../types/transactions";
import {Account} from "../types/accounts";
import TransactionController from "../classes/controller/TransactionController";
import {QUERY_KEYS} from "../constants";

export default function useTransactionTagMutation(account: Account, transaction: Transaction) {
  const queryClient = useQueryClient();

  return useMutation<List<Tag>, any, List<Tag>>(tags => TransactionController.updateTags(transaction, tags), {
    onSuccess: updatedTags => {
      queryClient.setQueryData<TagsByTransaction>([QUERY_KEYS.tags, account.id], oldData => {
        return oldData?.map((currentTags, transactionId) => {
          if (transactionId === transaction.id.toString()) {
            return updatedTags;
          }
          return currentTags;
        }) || Map<TransactionId, List<Tag>>()
      })
    }
  });
}
