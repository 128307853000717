//    Copyright 2021 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React, {useState} from "react";

import Button from "@material-ui/core/Button";

import PageContainer from "../helpers/PageContainer";

import SaveIcon from "@material-ui/icons/Save";
import InputFields from "./InputFields";
import {Map} from "immutable";
import {ByGroupAndName, Setting} from "../../types/settings";

import SettingsController from "../../classes/controller/SettingsController";

import useRequestedSettings from "../../hooks/useRequestedSettings";
import {useSettingsByGroupAndName} from "../../hooks/useSettingsByGroupAndName";

function makeSetting(group: string, name: string, value: string | number): Setting {
  const setting_type = typeof value === "string" ? "string" : "int";
  const value_int = typeof value === "number" ? value : 0;
  const value_string = typeof value === "string" ? value : "";

  return {
    id: -1,
    group,
    name,
    setting_type,
    value_int,
    value_string
  }
}

const Settings: React.FC = () => {
  const {data: requested, isFetching: isFetchingRequested} = useRequestedSettings();
  const settings = useSettingsByGroupAndName();
  const [changedSettings, setChangedSettings] = useState<ByGroupAndName>(Map());

  if (isFetchingRequested || !requested || !settings) {
    return null;
  }

  const settingChanged = (group: string, name: string, newValue: number | string) => {
    const keyPath = [group, name];
    const storedSetting = settings.getIn(keyPath);
    if (storedSetting == newValue || (storedSetting === undefined && newValue === "")) {
      setChangedSettings(changedSettings.deleteIn(keyPath));
    } else {
      const newSetting = makeSetting(group, name, newValue);
      setChangedSettings(changedSettings.setIn(keyPath, newSetting));
    }
  }

  function storeSettings() {
    changedSettings.forEach((names, group) => {
      names.forEach((value, name) => {
        const v = value.setting_type === "string" ? value.value_string : value.value_int;
        SettingsController.storeSetting(group, name, value.setting_type, v).then(() => {

        });
      })
    })
  }

  return <PageContainer title="Settings">
    <InputFields requested={requested} settings={settings} onSettingChanged={settingChanged}/>
    <Button
      color="primary"
      fullWidth
      size="large"
      startIcon={<SaveIcon/>}
      disabled={changedSettings.flatten().isEmpty()}
      onClick={storeSettings}
    >
      Save
    </Button>
  </PageContainer>;
}

export default Settings;
