//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React, {useState} from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import TagOverviewRows from "./TagOverviewRows";
import TagDetailsDialog from "./TagDetailsDialog";

import {Tag} from '../../types/tags';
import useAllTags from "../../hooks/useAllTags";
import useTagMutation from "../../hooks/useTagMutation";

const TagOverview: React.FC = () => {
  const allTags = useAllTags();
  const tagMutation = useTagMutation();
  const [editTag, setEditTag] = useState<Tag|undefined>(undefined);

  const onSubmit = (tag: Tag) => {
    tagMutation.mutate(tag);
  }

  return <div className="tag-overview__wrapper">
    <TagDetailsDialog
      onClose={() => setEditTag(undefined)}
      onSubmit={onSubmit}
      dialogTitle={`Edit ${editTag?.name}`}
      submitTitle="Update"
      tag={editTag}
    />
    <TableContainer component={Paper}>
      <Table aria-label="transactions table">
        <TableHead key="head">
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody key="body">
          <TagOverviewRows tags={allTags} onEditTag={setEditTag} />
        </TableBody>
      </Table>
    </TableContainer >
  </div>;
}

export default TagOverview;
