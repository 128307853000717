//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React, {useEffect, useState} from 'react';
import {Set} from 'immutable';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import {Account} from "../types/accounts";
import TransactionTableBody from "./TransactionTableBody";
import TransactionTableToolbar from "./TransactionTableToolbar";
import useTransactionsByAccount from "../hooks/useTransactionsByAccount";
import useTransactionDeletion from "../hooks/useTransactionDeletion";
import useTransactionTagsLoader from "../hooks/useTransactionTagsLoader";

interface TransactionTableProps {
  account: Account
}

const TransactionTable: React.FC<TransactionTableProps> = ({ account }) => {
  const transactions = useTransactionsByAccount(account);
  const tagLoader = useTransactionTagsLoader(account);
  const deleteTransactions = useTransactionDeletion(account);
  const [rowsSelected, setRowsSelected] = useState(Set<number>());

  function toggleRowSelected(transactionId: number) {
    if (rowsSelected.contains(transactionId)) {
      setRowsSelected(rowsSelected.delete(transactionId));
    } else {
      setRowsSelected(rowsSelected.add(transactionId));
    }
  }

  useEffect(() => {
    if (transactions && account) {
      tagLoader.mutate(transactions);
    }
  }, [transactions, account]);

  useEffect(() => {
    // transactions may only change if one or more have been deleted, so reset the selected rows
    setRowsSelected(Set<number>());
  }, [transactions]);

  if (transactions === undefined) {
    return null;
  }

  const onDeleteTransactions = () => {
    deleteTransactions.mutate(rowsSelected.toArray());
  }

  return (
    <Paper>
      <TransactionTableToolbar
        account={account}
        selectedRows={rowsSelected}
        onDeleteTransactions={onDeleteTransactions}
      />
      <TableContainer>
        <Table aria-label="transactions table">
          <TableHead key="head">
            <TableRow>
              <TableCell padding="checkbox"/>
              <TableCell>Subject</TableCell>
              <TableCell>Recipient</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Tags</TableCell>
            </TableRow>
          </TableHead>
          <TableBody key="body">
            <TransactionTableBody
              account={account}
              transactions={transactions}
              onSelectRow={toggleRowSelected}
              selectedRows={rowsSelected}
            />
          </TableBody>
        </Table>
      </TableContainer >
    </Paper>
  );
}

export default TransactionTable;
