//    Copyright 2021 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import {ThemeProvider} from "@material-ui/styles";
import {makeStyles} from "@material-ui/core/styles";
import CssBaseline from '@material-ui/core/CssBaseline';

import App from "./App";
import Header from "./Header";
import Sidebar from "./menu/Sidebar";

import {theme} from "../theme";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const Root: React.FC = () => {
  const classes = useStyles();

  return <div className={classes.root}>
    <Router>
      <ThemeProvider theme={theme}>
          <CssBaseline />
          <Header />
          <Sidebar/>
          <main className={classes.content}>
            <App />
          </main>
      </ThemeProvider>
    </Router>
  </div>;
};

export default Root;
