//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React from "react";

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import {OverridableComponent} from "@material-ui/core/OverridableComponent";
import {SvgIconTypeMap} from "@material-ui/core/SvgIcon";

import LanguageIcon from '@material-ui/icons/Language';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LinkIcon from '@material-ui/icons/Link';

import useSystemInfo from "../hooks/useSystemInfo";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 600,
            backgroundColor: theme.palette.background.paper,
        },
    }),
);

interface InfoListItemProps {
    name: string,
    version: string,
    icon: OverridableComponent<SvgIconTypeMap>,
    link: string,
}

function openLink(link: string) {
    window.open(link, "_blank");
}

const InfoListItem: React.FC<InfoListItemProps> = ({name, version, icon: Icon, link}) => {
    return <ListItem>
        <ListItemAvatar>
            <Avatar>
                <Icon/>
            </Avatar>
        </ListItemAvatar>
        <ListItemText primary={name} secondary={version} />
        <ListItemSecondaryAction>
            <Tooltip title="Show details">
                <IconButton edge="end" aria-label="delete" onClick={() => openLink(link)}>
                    <LinkIcon/>
                </IconButton>
            </Tooltip>
        </ListItemSecondaryAction>
    </ListItem>;
}

const Info: React.FC = () => {
    const classes = useStyles();
    const {data: info, isFetching} = useSystemInfo();

    if (isFetching || info === undefined) {
        return <div />;
    }

    return <List className={classes.root}>
        <InfoListItem
            name="ExOv Version"
            version={info.exovVersion.substr(0, 8)}
            icon={AccountBalanceIcon}
            link={info.exovSourceLink}
        />
        <InfoListItem
            name="Beego Version"
            version={info.beegoVersion}
            icon={LanguageIcon}
            link={info.beegoReleaseLink}
        />
    </List>
}

export default Info;