//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React, {Suspense} from 'react';

import Typography from "@material-ui/core/Typography";

import TagOverview from "./TagOverview";
import AddTag from "./AddTag";
import {CircularProgress} from "@material-ui/core";

const Tags: React.FC = () => {
  return <>
    <Typography variant="h6" component="h1">
      Tag Overview
    </Typography>
    <Suspense fallback={<CircularProgress/>}>
      <TagOverview />
    </Suspense>
    <AddTag />
  </>;
};

export default Tags;
