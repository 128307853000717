//    Copyright 2021 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React from "react";

import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";

import useTheme from "@material-ui/core/styles/useTheme";
import * as colors from "@material-ui/core/colors";

import {Doughnut} from 'react-chartjs-2';

import useSummaryValues from "../../hooks/useSummaryValues";
import useSummaryLabels from "../../hooks/useSummaryLabels";
import useSummaryLegend from "../../hooks/useSummaryLegend";

import {CHART_COLORS} from "../../constants";

import Balance from "../helpers/Balance";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  totalOverlay: {
    position: "absolute",

    top: 0,
    right: 0,
    left: 0,
    bottom: 0,

    pointerEvents: "none",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
})

const PieChart: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const summaryValues = useSummaryValues();
  const labels = useSummaryLabels();
  const legend = useSummaryLegend();

  const data = {
    datasets: [
      {
        data: summaryValues,
        backgroundColor: CHART_COLORS,
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return <Card>
    <CardHeader title="Transaction volume this month"/>
    <CardContent>
      <Box
        height={300}
        position="relative"
      >
        <Doughnut
          data={data}
          options={options}
        />
        <div className={classes.totalOverlay}>
          <Balance>{summaryValues.reduce((a, b) => a + b, 0)}</Balance>
        </div>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        flexWrap="wrap"
        mt={2}
      >
        {legend.map(entry => (
          <Box
            key={entry.label}
            p={1}
            textAlign="center"
          >
            <Typography
              color="textPrimary"
            >
              {entry.label}
            </Typography>
            <Typography
              style={{color: entry.color}}
            >
              <Balance>{entry.spending}</Balance>
            </Typography>
          </Box>
        ))}
      </Box>
    </CardContent>
  </Card>;
}

export default PieChart;
