//    Copyright 2021 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React from "react";
import {List} from "immutable";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";

import TransactionTableBody from "./TransactionTableBody";

import {Transaction} from "../../types/transactions";
import {TagsByTransaction, TagsUpdatedFunc} from "../../types/tags";

interface TransactionTableProps {
  transactions: List<Transaction>
  tags: TagsByTransaction,
  tagsUpdated: TagsUpdatedFunc,
}

const TransactionTable:  React.FC<TransactionTableProps> = ({transactions, tags, tagsUpdated}) => {
  return <TableContainer>
    <Table aria-label="transactions table">
      <TableHead key="head">
        <TableRow>
          <TableCell>Subject</TableCell>
          <TableCell>Recipient</TableCell>
          <TableCell align="right">Date</TableCell>
          <TableCell align="right">Amount</TableCell>
          <TableCell align="right">Tags</TableCell>
        </TableRow>
      </TableHead>
      <TableBody key="body">
        <TransactionTableBody transactions={transactions} tags={tags} tagsUpdated={tagsUpdated} />
      </TableBody>
    </Table>
  </TableContainer>;
}

export default TransactionTable;
