//    Copyright 2019 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from "@material-ui/core/Tooltip";
import Link from '@material-ui/core/Link';
import {createStyles, makeStyles} from "@material-ui/core/styles";

import HeaderMenu from './HeaderMenu';
import useUserData from "../hooks/useUserData";

const useStyles = makeStyles(theme =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
      justifyContent: 'space-between'
    },
    logo: {
      height: '100%'
    }
  }),
);

const Header: React.FC = () => {
  const {data: user, isFetching: isUserFetching} = useUserData();
  const classes = useStyles();

  const loggedIn = !isUserFetching && user?.loggedIn;

  return <AppBar position="fixed" className={classes.appBar}>
    <Toolbar className={classes.toolbar}>
      <Tooltip title="Home">
        <RouterLink to="/"><img alt="Logo" src="/logo/2.0/32.png" className={classes.logo}/></RouterLink>
      </Tooltip>
      {!loggedIn && (<Link color="inherit" href="/login/nextcloud">Login</Link>)}
      {loggedIn && <HeaderMenu />}
    </Toolbar>
  </AppBar>;
}

export default Header;
