//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import BaseController from "./BaseController";
import {SystemInfo} from "../../types/system";
import {getJson} from "../../helpers";

class SystemInfoController extends BaseController<SystemInfo> {
  constructor() {
    super("system");

    this.get = this.get.bind(this);
  }

  makeFromJson(o: any): SystemInfo {
    return {
      id: -1,  // just a workaround to make this type work with the BaseController
      exovVersion: o.exov_version,
      exovSourceLink: o.exov_source_link,
      beegoVersion: o.beego_version,
      beegoReleaseLink: o.beego_release_link,
      influxEnabled: o.influx_enabled,
    };
  }

  async get(): Promise<SystemInfo> {
    return this.makeFromJson(await getJson(this.apiUrl));
  }
}

export default new SystemInfoController();
