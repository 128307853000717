//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React, { useState } from 'react';

import Tooltip from "@material-ui/core/Tooltip";

import Fab from '@material-ui/core/Fab';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';

import TagDetailsDialog from "./TagDetailsDialog";

import {Tag} from "../../types/tags";
import useCreateTag from "../../hooks/useCreateTag";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
);

const AddTag: React.FC = () => {
  const classes = useStyles();
  const createTag = useCreateTag();
  const [open, setOpen] = useState(false);

  const submitForm = (tag: Tag) => {
    createTag.mutate(tag);
  }

  return <>
    <Tooltip title="Add new tag">
      <Fab className={classes.fab} color="primary" onClick={() => setOpen(true)}>
        <AddIcon />
      </Fab>
    </Tooltip>
    <TagDetailsDialog
      onClose={() => setOpen(false)}
      onSubmit={submitForm}
      dialogTitle="Add tag"
      submitTitle="Add"
      open={open}
    />
  </>
}

export default AddTag;
