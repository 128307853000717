//    Copyright 2019 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React from 'react';

import TransactionList from "../transactions/TransactionList";

import {Transaction} from "../../types/transactions";
import {List} from "immutable";
import {Tag} from "../../types/tags";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import Tooltip from "@material-ui/core/Tooltip";
import useUnassignedTransactions from "../../hooks/useUnassignedTransactions";
import useUnassignedTransactionTags from "../../state/useUnassignedTransactionTags";
import useSummary from "../../hooks/useSummary";

const NonAssignedTransactions: React.FC = () => {
  const {refetch: refetchSummary} = useSummary();
  const {data: transactions, refetch: refetchUnassignedTransactions} = useUnassignedTransactions();
  const tagsByTransaction = useUnassignedTransactionTags(state => state.tags);
  const setTransactionTags = useUnassignedTransactionTags(state => state.setTransactionTags);
  const clearTempTags = useUnassignedTransactionTags(state => state.clear)

  if (!transactions) {
    return null;
  }

  const updateTagsByTransaction = (transaction: Transaction, updatedTags: List<Tag>) => {
    if (transaction.id != null) {
      setTransactionTags(transaction, updatedTags);
    }
  };

  const renderActionComponents = () => {
    return <>
      <Tooltip title="Refresh">
        <IconButton
          aria-label="refresh"
          onClick={() => {
            refetchUnassignedTransactions();
            refetchSummary();
            clearTempTags();
          }}
        >
          <RefreshIcon/>
        </IconButton>
      </Tooltip>
    </>;
  }

  return <TransactionList
    title="Transactions without assigned tags"
    transactions={transactions}
    tags={tagsByTransaction}
    tagsUpdated={updateTagsByTransaction}
    renderActionComponents={renderActionComponents}
  />;
}

export default NonAssignedTransactions;
