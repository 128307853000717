//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React, {useEffect, useState, Suspense} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from '@material-ui/core/Grid';
import makeStyles from "@material-ui/core/styles/makeStyles";


import AccountList from './AccountList';
import HiddenAccounts from "./HiddenAccounts";
import {Account} from "../../types/accounts";
import NonAssignedTransactions from "./NonAssignedTransactions";
import PieChart from "./PieChart";

import useAccounts from "../../hooks/useAccounts";

const useStyles = makeStyles((theme) => ({
  pieChart: {
    marginTop: theme.spacing(2),
  }
}));

const Home: React.FC = () => {
  const classes = useStyles();
  const [visibleAccounts, setVisibleAccounts] = useState<Account[]>([]);
  const [hiddenAccounts, setHiddenAccounts] = useState<Account[]>([]);
  const {data, isFetching} = useAccounts();

  useEffect(() => {
    if (data === undefined) {
      return;
    }
    setHiddenAccounts(data.filter(a => a.hidden).toArray());
    setVisibleAccounts(data.filter(a => !a.hidden).toArray());
  }, [data]);

  if (isFetching) {
    return <CircularProgress/>;
  }

  return <div>
    <Suspense fallback={<CircularProgress/>}>
      <NonAssignedTransactions />
    </Suspense>

    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <div className={classes.pieChart}>
          <PieChart />
        </div>
      </Grid>
      <Grid item xs={12} md={8}>
        <AccountList accounts={visibleAccounts} displayFavorites={true} />
        <AccountList accounts={visibleAccounts} displayFavorites={false} />
      </Grid>
    </Grid>

    <HiddenAccounts hiddenAccounts={hiddenAccounts}/>
  </div>;
}

export default Home;
