//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import BaseController from "./BaseController";
import Immutable, {List, Map} from "immutable";

import {Tag, TagsByTransaction, TransactionId} from "../../types/tags";
import {getJson} from "../../helpers";
import {Account} from "../../types/accounts";
import {Transaction} from "../../types/transactions";

class TagController extends BaseController<Tag> {
  constructor() {
    super("tag");

    this.getAll = this.getAll.bind(this);
  }

  makeFromJson(o: any): Tag {
    return {
      id: o.id,
      name: o.name,
      description: o.description,
      parent_id: o.parent_id,
    };
  }

  async getAll(): Promise<List<Tag>> {
    const result: [] = await getJson(this.apiUrl);
    return List(result.map(t => this.makeFromJson(t)));
  }

  private static findFromAndTo(transactions: List<Transaction>) {
    let from = Infinity;
    let to = 0;

    for (const transaction of transactions.toArray()) {
      if (transaction.id === undefined) {
        // this is a newly created transaction, skip it
        continue;
      }

      if (transaction.id < from) {
        from = transaction.id;
      }

      if (transaction.id > to) {
        to = transaction.id;
      }
    }

    return { from, to };
  }

  async getForTransactions(account: Account, transactions: List<Transaction>): Promise<TagsByTransaction> {
    const { from, to } = TagController.findFromAndTo(transactions);

    const url = new URL(this.makeSubUrl("fortransaction"));
    url.searchParams.set("account_id", String(account.id));
    url.searchParams.set("transaction_start_id", String(from));
    url.searchParams.set("transaction_end_id", String(to));

    const result = Immutable.Map(await getJson(url.toString())) as Map<TransactionId, Tag[]>;

    return result.map(tags => List(tags));
  }
}

export default new TagController();
