//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React from "react";
import Balance from "../helpers/Balance";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  balanceTextWrapper: {
    padding: '.3rem',
    backgroundColor: 'rgba(255, 255, 255, 0.8);',
    borderRadius: '.3rem',
  }
}));

interface AccountBalanceDisplayProps {
  balance: number
}

const AccountBalanceDisplay: React.FC<AccountBalanceDisplayProps> = ({balance}) => {
  const classes = useStyles();

  return <span className={classes.balanceTextWrapper}>
    <Balance>{balance}</Balance>
  </span>;
};

export default AccountBalanceDisplay;
