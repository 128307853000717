//    Copyright 2021 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import create from "zustand";
import {Tag, TagsByTransaction, TransactionId} from "../types/tags";
import {List, Map} from "immutable";
import {Transaction} from "../types/transactions";
import TransactionController from "../classes/controller/TransactionController";

const getDefault = () => Map<TransactionId, List<Tag>>();

interface UnassignedTransactionTagsState {
  tags: TagsByTransaction
  setTransactionTags: (transaction: Transaction, updatedTags: List<Tag>) => void
  clear: () => void
}

const useUnassignedTransactionTags = create<UnassignedTransactionTagsState>((set, get) => ({
  tags: getDefault(),

  setTransactionTags: (transaction, updatedTags) => {
    TransactionController.updateTags(transaction, updatedTags);
    return set({
      tags: get().tags.set(transaction.id.toString(), updatedTags)
    });
  },

  clear: () => set({
    tags: getDefault()
  })
}));

export default useUnassignedTransactionTags;
