//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React from 'react';

import {Account} from "../../types/accounts";

import AccountRenderer from "./Account";
import './AccountList.scss';

interface AccountListProps {
  accounts: Account[]
  displayFavorites?: boolean
}

const AccountList: React.FC<AccountListProps> = ({accounts, displayFavorites}) => {
  return <div className="accountlist">
    {accounts.filter(a => displayFavorites ? a.favorite : !a.favorite).map(a => (
      <AccountRenderer account={a} key={a.iban} />
    ))}
  </div>;
}

export default AccountList;
