//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React from "react";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import {Account} from "../../types/accounts";

import AccountList from './AccountList';
import makeStyles from "@material-ui/core/styles/makeStyles";

interface HiddenAccountsProps {
  hiddenAccounts: Account[]
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2)
  }
}));

const HiddenAccounts: React.FC<HiddenAccountsProps> = ({hiddenAccounts}) => {
  const classes = useStyles();

  if (hiddenAccounts.length === 0) {
    return null;
  }

  return <Accordion className={classes.container}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="accordion-hidden-accounts-content"
      id="accordion-hidden-accounts"
    >
      <Typography>Hidden accounts</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <AccountList accounts={hiddenAccounts} displayFavorites={true} />
      <AccountList accounts={hiddenAccounts} />
    </AccordionDetails>
  </Accordion>;
}

export default HiddenAccounts;
