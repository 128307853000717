//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React, {useState} from "react";
import {Set} from "immutable";
import clsx from 'clsx';

import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import {makeStyles, lighten} from "@material-ui/core/styles";

import ConfirmationDialog from "./helpers/ConfirmationDialog";

import {Account} from "../types/accounts";


interface TransactionTableToolbarProps  {
  account: Account
  selectedRows: Set<number>
  onDeleteTransactions: () => void
}

const useStyles = makeStyles((theme) => ({
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  }
}));

const TransactionTableToolbar: React.FC<TransactionTableToolbarProps> = ({account, selectedRows, onDeleteTransactions}) => {
  const classes = useStyles();
  const showActions = selectedRows.size > 0;
  const [showConfirmation, setShowConfirmation] = useState(false);

  return <>
    <ConfirmationDialog
      title="Confirm deletion"
      message={`Do you really want to delete ${selectedRows.size} transactions?`}
      onConfirm={onDeleteTransactions}
      open={showConfirmation}
      onClose={() => setShowConfirmation(false)}
    />
    <Toolbar className={clsx({[classes.highlight]: showActions})}>
      {showActions ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {selectedRows.size} transactions selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {account.name}
        </Typography>
      )}

      {showActions ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon
              onClick={() => setShowConfirmation(true)}
            />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  </>;
}

export default TransactionTableToolbar;
