//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import {Base} from "../../types/base";
import {deleteJson, patchJson, postJson} from "../../helpers";

const API_URL: string = "/api/v1/{restName}"

abstract class BaseController<T extends Base> {
  protected readonly apiUrl: string;

  protected constructor(restName: string) {
    this.apiUrl = window.location.origin + API_URL.replace(/{restName}/, restName);

    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
    this.deleteMultiple = this.deleteMultiple.bind(this);

    this.makeSubUrl = this.makeSubUrl.bind(this);
  }

  public abstract makeFromJson(o: any): T;

  public makeObjectUrl(obj: Partial<T>, child: string | undefined = undefined): string {
    if (obj.id === undefined) {
      throw new Error("need the object id to make identify the object, but it was set to undefined");
    }
    return this.makeSubUrl(String(obj.id), child);
  }

  protected makeSubUrl(sub: string, child: string | undefined = undefined): string {
    const partial = `${this.apiUrl}/${sub}`;

    if (child === undefined) {
      return partial;
    }
    return `${partial}/${child}`;
  }

  public async create(obj: T): Promise<T> {
    const payload: Partial<T> = {...obj};
    if (payload.id === -1) {
      // -1 is the NotSetValue, do not send it to the backend
      delete payload.id;
    }

    const result = await postJson(this.apiUrl, payload);

    return this.makeFromJson(result);
  }

  public async update(obj: Partial<T>): Promise<T> {
    const result = await patchJson(this.makeObjectUrl(obj), obj);

    return this.makeFromJson(result);
  }

  public async deleteMultiple(ids: number[]): Promise<void> {
    for (const id of ids) {
      await deleteJson(this.makeSubUrl(String(id)));
    }
  }
}

export default BaseController;