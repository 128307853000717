//    Copyright 2021 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import {useQuery} from "react-query";
import AccountController from "../classes/controller/AccountController";
import useSystemInfo from "./useSystemInfo";
import useAccounts from "./useAccounts";

export default function useAccountBalanceHistory(accountId: number) {
  const {data: systemInfo} = useSystemInfo();
  const {data: accounts} = useAccounts();

  return useQuery(["accountBalance", accountId], async () => {
    if (accounts === undefined || systemInfo === undefined) {
      // should not happen
      throw new Error();
    }

    const account = accounts.find(a => a.id === accountId);
    if (account === undefined) {
      throw new Error("could not find account with id " + accountId);
    }

    if (account.hidden || !systemInfo) {
      return [];
    }

    return AccountController.getBalanceHistory(accountId);
  }, {
    staleTime: Infinity,
    enabled: systemInfo !== undefined && accounts !== undefined
  });
}
