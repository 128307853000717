//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React from 'react';

interface IbanProps {
  children: string
}

function formatIban(iban: string): string {
  const splitted = iban.match(/.{1,4}/g);
  if (splitted === null || splitted.length === 0) {
    return '';
  }
  return String(iban.match(/.{1,4}/g)?.join(' '));
}

const Iban: React.FC<IbanProps> = ({ children }) => {
  return <>{formatIban(children)}</>;
}

export default Iban
