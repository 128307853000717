//    Copyright 2021 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import {Breakpoint} from "@material-ui/core/styles/createBreakpoints";
import * as colors from "@material-ui/core/colors";

export const PERMANENT_SIDEBAR = new Set<Breakpoint>([
  "xl",
  "lg",
  "md",
]);

export const TEMPORARY_SIDEBAR = new Set<Breakpoint>([
  "sm",
  "xs"
]);

export const ONLY_SMALL = [...TEMPORARY_SIDEBAR];
export const ONLY_LARGE = [...PERMANENT_SIDEBAR];

export const CHART_COLORS = [
  colors.indigo[500],
  colors.yellow[700],
  colors.red[600],
  colors.teal[500],
  colors.orange[600],
  colors.purple[500],
  colors.blue[500],
  colors.green[600],
  colors.cyan[600],
  colors.amber[500],
];

export const QUERY_KEYS = {
  allAccounts: "allAccounts",
  allTags: "allTags",
  systemInfo: "systemInfo",
  userData: "userData",
  requestedSettings: "requestedSettings",
  settings: "settings",
  summary: "summary",
  transactions: "transactions",
  tags: "tags",
  unassigedTransactions: "unassigedTransactions"
}

export enum TextColorType {
  Light,
  Dark
}
