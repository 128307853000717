//    Copyright 2019 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React from 'react';
import {List} from "immutable";

import Paper from '@material-ui/core/Paper';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

import TransactionTable from "./TransactionTable";
import TransactionListing from "./TransactionListing";

import {Transaction} from "../../types/transactions";
import {TagsByTransaction, TagsUpdatedFunc} from "../../types/tags";
import Hidden from "@material-ui/core/Hidden";
import {ONLY_LARGE, ONLY_SMALL} from "../../constants";

interface TransactionTableProps {
  transactions: List<Transaction>
  title: string
  tags: TagsByTransaction,
  tagsUpdated: TagsUpdatedFunc,
  renderActionComponents?: () => JSX.Element
}

const useStyles = makeStyles(() => ({
  title: {
    flex: '1 1 100%',
  }
}));

const TransactionList: React.FC<TransactionTableProps> = (
  {
    transactions,
    tags,
    title,
    tagsUpdated,
    renderActionComponents,
  }
  ) => {
  const classes = useStyles();

  if (transactions.isEmpty()) {
    return null;
  }

  const actionComponents = renderActionComponents ? renderActionComponents() : null;

  return <Paper>
    <Toolbar>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        {title}
      </Typography>

      {actionComponents}
    </Toolbar>

    <Hidden only={ONLY_SMALL}>
      <TransactionTable transactions={transactions} tags={tags} tagsUpdated={tagsUpdated}/>
    </Hidden>
    <Hidden only={ONLY_LARGE}>
      <TransactionListing transactions={transactions} tags={tags} tagsUpdated={tagsUpdated}/>
    </Hidden>
  </Paper>;
}

export default TransactionList;
