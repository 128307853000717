//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React, {useEffect, useState} from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {Tag} from "../../types/tags";
import TextField from "@material-ui/core/TextField";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import useAllTags from "../../hooks/useAllTags";

interface TagDetailsDialogProps {
  tag?: Tag
  open?: boolean
  onClose: () => void
  onSubmit: (tag: Tag) => void
  dialogTitle: string
  submitTitle: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submit: {
      display: 'none'
    },
    parentDropdown: {
      minWidth: "100%",
      marginTop: theme.spacing(1)
    }
  }),
);

const TagDetailsDialog: React.FC<TagDetailsDialogProps> = ({tag, onClose, dialogTitle, submitTitle, onSubmit, open}) => {
  const classes = useStyles();
  const tags = useAllTags();
  const [name, setName] = useState<string>(tag?.name || "");
  const [description, setDescription] = useState<string>(tag?.description || "");
  const [parentId, setParentId] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (tag) {
      setName(tag.name);
    }
  }, [tag, setName]);

  useEffect(() => {
    if (tag) {
      setDescription(tag.description);
    }
  }, [tag, setDescription]);

  useEffect(() => {
    if (tag) {
      setParentId(tag.parent_id);
    }
  }, [tag, setParentId]);

  const submit = () => {
    if (tag) {
      const result: Tag = {
        id: tag.id,
        parent_id: parentId,
        description,
        name,
      }

      onSubmit(result);
    } else {
      const result: Tag = {
        id: -1,
        parent_id: parentId,
        description,
        name,
      }

      onSubmit(result);
    }

    onClose();
  }

  return <Dialog open={tag !== undefined || open === true} onClose={onClose} aria-labelledby="tag-edit-dialog">
    <DialogTitle id="tag-edit-dialog-title">{dialogTitle}</DialogTitle>
    <DialogContent>
      <form onSubmit={submit}>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          defaultValue={name}
          onChange={e => setName(e.target.value)}
        />
        <TextField
          margin="dense"
          id="name"
          label="Description"
          type="text"
          multiline
          rowsMax={5}
          fullWidth
          variant="outlined"
          defaultValue={description}
          onChange={e => setDescription(e.target.value)}
        />
        <FormControl variant="outlined" className={classes.parentDropdown}>
          <InputLabel id="tag-parent-id-label">Parent</InputLabel>
          <Select
            labelId="tag-parent-id-label"
            id="tag-parent-id-select"
            value={parentId || ""}
            onChange={e => setParentId(e.target.value as number)}
            label="Parent"
          >
            {tags.filter(t => tag === undefined || tag.id !== t.id).map(t => <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>)}
          </Select>
        </FormControl>
        <input type="submit" className={classes.submit} />
      </form>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="default">
        Cancel
      </Button>
      <Button onClick={submit} color="primary">
        {submitTitle}
      </Button>
    </DialogActions>
  </Dialog>;
}

export default TagDetailsDialog;