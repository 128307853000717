//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

interface ConfirmationDialogProps {
  onConfirm: () => any
  open: boolean
  onClose: () => any
  title?: string
  message?: string
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({onConfirm, onClose, open, title, message}) => {
  const showTitle = title || 'Confirm';
  const content = message || 'Do you want to proceed?';

  const handleConfirm = () => {
    onConfirm();
    onClose();
  }

  return <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="confirmation-dialog">{showTitle}</DialogTitle>
    <DialogContent>
      {content}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="default">
        No
      </Button>
      <Button onClick={handleConfirm} color="primary">
        Yes
      </Button>
    </DialogActions>
  </Dialog>;
}

export default ConfirmationDialog;
