//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import {TextColorType} from "../constants";
import {RgbColor} from "../types/util";

function internalFetch(url: string, method: string = 'GET', init: RequestInit = {}): Promise<Response> {
  const commonInit : RequestInit = {
    method,
    credentials: 'include'
  };
  const mergedInit = Object.assign({}, commonInit, init);

  return new Promise(resolve => {
    fetch(url, mergedInit).then(response => {
      if (response.ok) {
        resolve(response);
      } else {
        // TODO error handling
      }
    }).catch(response => {
      // TODO error handling
    });
  })
}

async function processResponse(response: Response): Promise<any> {
  if (response.status >= 200 && response.status < 500 && response.status !== 204) {
    return response.json();
  }
  return null;
}

/**
* Fetches a given url and returns the value as json.
*
* @param {string} url url to fetch
* @returns {Promise} a promise passing through the parsed json
*/
export async function getJson(url: string): Promise<any> {
  const response = await internalFetch(url);
  return processResponse(response);
}

async function sendJsonWithBody(url: string, method: string, data: Object | undefined): Promise<any> {
  if (data === undefined) {
    data = {};
  }
  const init = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };

  const response = await internalFetch(url, method, init);
  return processResponse(response);
}

/**
* Sends a post request to the given url containing the data given as request body
*
* @param {string} url url to fetch
* @param {object} data data to send
*/
export function postJson(url: string, data: Object | undefined = undefined): Promise<any> {
  return sendJsonWithBody(url, 'POST', data);
}

export function patchJson(url: string, data: Object | undefined = undefined): Promise<any> {
  return sendJsonWithBody(url, 'PATCH', data);
}

export function deleteJson(url: string, data: Object | undefined = undefined): Promise<any> {
  return sendJsonWithBody(url, 'DELETE', data);
}

export function maxLength(text: string, max: number = 50): string {
  if (text.length > max) {
    return text.slice(0, max) + "…";
  }
  return text;
}

export function formatAmount(balance: number): string {
  return String(balance.toFixed(2)).replace(/\./, ',') + "€";
}

function makeNumberSeed(seed: string): number {
  let result = 0;
  for (let i = 0; i < seed.length; i++) {
    result += (i + 1) * seed.charCodeAt(i);
  }
  return result;
}

function hexToRgb(hex: string): RgbColor {
  const strippedHex = hex.replace("#", "");

  const parts = strippedHex.match(/.{1,2}/g);
  if (parts === null) {
    throw new Error("parts must be defined");
  }
  if (parts.length != 3) {
    throw new Error("expected parts to have length 3, but got length " + parts.length);
  }

  return {
    r: parseInt(parts[0], 16),
    g: parseInt(parts[1], 16),
    b: parseInt(parts[2], 16),
  }
}

// adapted from https://en.wikipedia.org/wiki/Luma_%28video%29
function relativeLuminance(hex: string): number {
  const {r, g, b} = hexToRgb(hex);

  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

export function generateColor(seed: string): string {
  const numberSeed = makeNumberSeed(seed);
  const color = Math.floor((Math.abs(Math.sin(numberSeed) * 16777215)) % 16777215);
  const stringColor = color.toString(16);
  // pad any colors shorter than 6 characters with leading 0s
  let result = stringColor;
  while(stringColor.length < 6) {
    result = "0" + stringColor;
  }

  return "#" + result;
}

// adapted from https://stackoverflow.com/a/6511606
export function getTextColorTypeForBackgroundColor(backgroundColor: string): TextColorType {
  const luma = relativeLuminance(backgroundColor);

  if (luma > 165) {
    return TextColorType.Light;
  }
  return TextColorType.Dark;
}
