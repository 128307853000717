//    Copyright 2021 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React from "react";

import {Link} from 'react-router-dom';

import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import InfoIcon from '@material-ui/icons/Info';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from '@material-ui/icons/Settings';

import {ONLY_LARGE, ONLY_SMALL, PERMANENT_SIDEBAR} from "../../constants";

import {SidebarEntry} from "../../types/sidebar";
import SidebarLinkSection from "./SidebarLinkSection";
import useUserData from "../../hooks/useUserData";
import useSidebar from "../../state/useSidebar";

const links: SidebarEntry[] = [
  {
    type: "link",
    label: "Dashboard",
    target: "/",
    Icon: DashboardIcon
  },
  {
    type: "link",
    label: "Tags",
    target: "/tags",
    Icon: LocalOfferIcon
  },
  {type: "divider"},
  {
    type: "link",
    label: "Profile",
    target: "/user/profile",
    Icon: AccountCircleIcon
  },
  {
    type: "link",
    label: "Info",
    target: "/info",
    Icon: InfoIcon
  },
  {
    type: "link",
    label: "Settings",
    target: "/settings",
    Icon: SettingsIcon
  },
  {type: "divider", hidden: ONLY_LARGE},
  {
    type: "link",
    label: "Logout",
    target: "/logout",
    Icon: ExitToAppIcon,
    hidden: ONLY_LARGE,
    disableRouting: true
  },
];

const drawerWidth = 280;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  name: {
    fontSize: 16,
    fontWeight: 500,
    marginTop: theme.spacing(.5)
  }
}));

const Sidebar: React.FC<WithWidth> = ({width}) => {
  const {data: user, isFetching: isUserFetching} = useUserData();

  const show = useSidebar(state => state.showSidebar);
  const setShow = useSidebar(state => state.setShowSidebar);
  const classes = useStyles();

  if (isUserFetching || user === undefined || !user.loggedIn) {
    return null;
  }

  return <Drawer
    anchor="left"
    open={show}
    onClose={() => setShow(false)}
    variant={PERMANENT_SIDEBAR.has(width) ? "permanent" : "temporary"}
    classes={{
      paper: classes.drawerPaper,
    }}
    className={classes.drawer}
  >
    <Hidden only={ONLY_SMALL}>
      <Toolbar />
    </Hidden>
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      p={2}
    >
      <Avatar
        className={classes.avatar}
        component={Link}
        to="/user/profile"
        onClick={() => setShow(false)}
      />
      <Typography
        color="textPrimary"
        variant="h5"
        className={classes.name}
      >
        {user.user?.user_id}
      </Typography>
    </Box>
    <Divider/>
    <div className={classes.drawerContainer}>
      <SidebarLinkSection links={links}/>
    </div>
  </Drawer>
};

export default withWidth()(Sidebar);
