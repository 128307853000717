//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React, {Suspense} from 'react';
import { useParams } from 'react-router-dom';

import {CircularProgress} from "@material-ui/core";

import { Account } from '../types/accounts';

import AddTransaction from './AddTransaction';
import TransactionTable from './TransactionTable';
import useAccountById from "../hooks/useAccountById";

interface AccountDetailsProps {
  account: Account
}

const AccountDetails: React.FC<AccountDetailsProps> = ({ account }) => {
  return <div className="account-details">
    <Suspense fallback={<CircularProgress/>}>
      <TransactionTable account={account} />
    </Suspense>
    <Suspense fallback={null}>
      <AddTransaction account={account} />
    </Suspense>
  </div>;
}

const DetailHelper: React.FC = () => {
  const {accountId} = useParams<{accountId: string}>();
  const account = useAccountById(Number(accountId));

  if (account === undefined) {
    return null;
  }

  return <AccountDetails account={account} />;
}

export default DetailHelper;
