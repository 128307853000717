//    Copyright 2021 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import {useMutation, useQueryClient} from "react-query";
import {List} from "immutable";

import AccountController from "../classes/controller/AccountController";
import {Account} from "../types/accounts";

import {QUERY_KEYS} from "../constants";
import {Tag} from "../types/tags";
import TagController from "../classes/controller/TagController";

export default function useTagMutation() {
  const queryClient = useQueryClient();

  return useMutation<Tag, any, Partial<Tag>>(TagController.update, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData<List<Tag>>(QUERY_KEYS.allTags, oldData => oldData?.map(t => {
        if (t.id === variables.id) {
          return data;
        }
        return t;
      }) || List());
    }
  });
}
