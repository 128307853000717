//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React, { FormEvent } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from "@material-ui/core/Tooltip";

import Fab from '@material-ui/core/Fab';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import TodayIcon from '@material-ui/icons/Today';
import EuroIcon from '@material-ui/icons/Euro';
import ShortTextIcon from '@material-ui/icons/ShortText';

import { Account } from '../types/accounts';
import { Transaction } from '../types/transactions';
import useCreateTransaction from "../hooks/useCreateTransaction";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    submit: {
      display: 'none'
    }
  }),
);

interface AddTransactionProps {
  account: Account
}

const AddTransaction: React.FC<AddTransactionProps> = ({ account }) => {
  const classes = useStyles();
  const createTransaction = useCreateTransaction(account);
  const [open, setOpen] = React.useState(false);
  const [amount, setAmount] = React.useState<number | null>(null);
  const [subject, setSubject] = React.useState<string | null>(null);
  const [date, setDate] = React.useState<Date>(new Date());

  const handleClickOpen = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setSubject(null);
    setAmount(null);
    setDate(new Date());
    setOpen(false);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (amount === null) {
      throw Error('cannot save transaction');
    }

    const transaction: Transaction = {
      id: -1,
      amount,
      recipient: '',
      date,
      subject: subject || '',
      account
    }

    createTransaction.mutate(transaction);

    closeDialog();
  }

  return (
    <div>
      <Tooltip title="Add new transaction">
        <Fab color="primary" aria-label="add" className={classes.fab} onClick={handleClickOpen}>
          <AddIcon />
        </Fab>
      </Tooltip>
      <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add transaction</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              autoFocus
              margin="dense"
              id="amount"
              label="Amount"
              type="number"
              fullWidth
              variant="outlined"
              defaultValue={amount}
              onChange={e => setAmount(Number(e.target.value))}
              InputProps={{
                startAdornment: < InputAdornment position="start"><EuroIcon /></InputAdornment>
              }}
            />
            <TextField
              margin="dense"
              id="subject"
              label="Subject"
              type="text"
              fullWidth
              variant="outlined"
              defaultValue={subject}
              onChange={e => setSubject(e.target.value)}
              InputProps={{
                startAdornment: < InputAdornment position="start"><ShortTextIcon /></InputAdornment>
              }}
            />
            <TextField
              margin="dense"
              id="date"
              label="Date"
              type="date"
              fullWidth
              defaultValue={date.toLocaleDateString('en-CA')}
              variant="outlined"
              onChange={e => setDate(new Date(e.target.value))}
              InputProps={{
                startAdornment: <InputAdornment position="start"><TodayIcon /></InputAdornment>
              }}
            />
            <input type="submit" className={classes.submit} />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="default">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddTransaction;
