//    Copyright 2021 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React from "react";
import {List} from "immutable";
import {ByGroupAndName, RequestedSetting} from "../../types/settings";

import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {FormHelperText} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import SettingsController from "../../classes/controller/SettingsController";

interface InputFieldsProps {
  requested: List<RequestedSetting>
  settings: ByGroupAndName
  onSettingChanged: (group: string, name: string, changedValue: number | string) => void
}

const useStyles = makeStyles(theme => ({
  helperText: {
    marginBottom: theme.spacing(.5)
  },
  groupLabel: {
    marginTop: theme.spacing(.5)
  },
  input: {
    marginTop: theme.spacing(.5)
  }
}));

function parseSettingValue(s: RequestedSetting, val: string): string | number {
  if (s.setting_type === "string") {
    return val;
  }

  return parseInt(val);
}

const InputFields: React.FC<InputFieldsProps> = ({requested, settings, onSettingChanged}) => {
  const classes = useStyles();

  return <form>
    {requested.map((r, id) => {
      const result = [];

      if (id != 0 && requested.get(id - 1)?.group != r.group) {
        // group change
        result.push(<Divider key={`group-divider-${id}`}/>)
      }

      if (requested.get(id - 1)?.group != r.group) {
        result.push(
          <Typography
            className={classes.groupLabel}
            variant="subtitle1"
            id={`id-group-${id}`}
            key={`title-group-${id}`}
            role="heading"
            component="h2"
          >
            {r.group}
          </Typography>
        )
      }

      const key = r.group + r.name

      result.push(
        <FormControl fullWidth key={`control-group-${id}`}>
          <TextField
            id={key}
            key={key}
            label={r.name}
            variant="outlined"
            fullWidth
            defaultValue={SettingsController.getSettingValue(settings.getIn([r.group, r.name]))}
            onChange={e => onSettingChanged(r.group, r.name, parseSettingValue(r, e.target.value))}
            type={r.setting_type === "string" ? "text" : "number"}
            className={classes.input}
          />
          <FormHelperText key={key + "helper"} id={key + "helper"} className={classes.helperText}>
            {r.description}
          </FormHelperText>
        </FormControl>
      )

      return result;
    })}
  </form>;
}

export default InputFields;
