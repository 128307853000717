//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React from 'react';
import {List} from "immutable";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import DateRenderer from "../helpers/DateRenderer";
import Balance from "../helpers/Balance";

import {Transaction} from "../../types/transactions";
import {TagsByTransaction, TagsUpdatedFunc} from "../../types/tags";
import TransactionTags from "./TransactionTags";

interface TransactionTableBodyProps {
  transactions: List<Transaction>
  tags: TagsByTransaction
  tagsUpdated: TagsUpdatedFunc
}

const TransactionTableBody: React.FC<TransactionTableBodyProps> = ({transactions, tags, tagsUpdated}) => {
  const t = transactions.map(t => (
    <TableRow
      key={t.subject + t.date.toISOString()}
    >
      <TableCell scope="row">{t.subject}</TableCell>
      <TableCell>{t.recipient}</TableCell>
      <TableCell align="right"><DateRenderer>{t.date}</DateRenderer></TableCell>
      <TableCell align="right"><Balance>{t.amount}</Balance></TableCell>
      <TableCell align="right">
        <TransactionTags
          tags={tags.get(t.id.toString(), List())}
          transaction={t}
          tagsUpdated={tagsUpdated}
        />
      </TableCell>
    </TableRow>
  )).toArray();

  return <>
    {t}
  </>;
}

export default TransactionTableBody;
