//    Copyright 2021 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React from "react";

import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography';
import Toolbar from "@material-ui/core/Toolbar";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(1),
    paddingTop: 0
  }
}));

interface PageContainerProps {
  title: string
}

const PageContainer: React.FC<PageContainerProps> = ({title, children}) => {
  const classes = useStyles();

  return <Paper>
    <Toolbar>
      <Typography variant="h6" id="tableTitle" component="h2">
        {title}
      </Typography>
    </Toolbar>

    <div className={classes.content}>
      {children}
    </div>
  </Paper>;
}

export default PageContainer;
