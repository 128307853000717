//    Copyright 2021 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React, {useState} from "react";
import Immutable from "immutable";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from '@material-ui/core/TextField';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from "@material-ui/core/IconButton";

import DeleteIcon from '@material-ui/icons/Delete';

import Button from "@material-ui/core/Button";

import {Transaction} from "../../types/transactions";
import {maxLength} from "../../helpers";
import {Tag, TagsUpdatedFunc} from "../../types/tags";
import useAllTags from "../../hooks/useAllTags";


function addToExisting(transaction: Transaction | undefined, tag: Tag | null, tags: Tag[]): Immutable.List<Tag> {
  if (transaction === undefined) {
    throw new Error("got no transaction");
  }
  if (tag !== null) {
    tags.push(tag);
  }
  return Immutable.List(tags);
}

function removeFromExisting(transaction: Transaction | undefined, tag: Tag, allTags: Tag[]): Immutable.List<Tag> {
  if (transaction === undefined) {
    throw new Error("got no transaction");
  }

  return Immutable.List(allTags.filter(t => t.id !== tag.id));
}


interface TransactionTagEditDialogProps {
  transaction?: Transaction
  onClose: () => void
  tags: Tag[],
  tagsUpdated: TagsUpdatedFunc,
}

const TransactionTagEditDialog: React.FC<TransactionTagEditDialogProps> = ({transaction, onClose, tagsUpdated, tags}) => {
  const allTags = useAllTags();
  const [inputText, setInputText] = useState<string>("");

  return <Dialog open={transaction !== undefined} onClose={onClose} aria-labelledby="transaction-tag-edit-dialog">
    <DialogTitle>{`Edit tags for: ${maxLength(transaction?.subject || '', 40)}`}</DialogTitle>
    <DialogContent>
      <DialogContentText id="transaction-edit-dialog-recipient">
        {transaction?.recipient}
      </DialogContentText>
      <List>
        {tags.map(t => (
          <ListItem>
            <ListItemText
              primary={t.name}
              secondary={t.description}
            />
            <ListItemSecondaryAction>
              <IconButton onClick={() => tagsUpdated(
                transaction as Transaction, removeFromExisting(transaction, t, tags)
              )}>
                <DeleteIcon/>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Autocomplete
        getOptionLabel={t => t.name}
        options={allTags}
        renderInput={params => <TextField
          {...params}
          variant="outlined"
          placeholder="Tag"
          onChange={event => setInputText(event.target.value)}
        />}
        onChange={(event, value) => {
          tagsUpdated(transaction as Transaction, addToExisting(transaction, value, tags));
          setInputText("");
        }}
        inputValue={inputText}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="default">
        Close
      </Button>
    </DialogActions>
  </Dialog>;
}

export default TransactionTagEditDialog;
