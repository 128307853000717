//    Copyright 2021 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import BaseController from "./BaseController";
import {RequestedSetting, Setting} from "../../types/settings";
import {List} from "immutable";
import {getJson, postJson} from "../../helpers";

class SettingsController extends BaseController<Setting> {
  constructor() {
    super("settings");

    this.getAll = this.getAll.bind(this);
    this.getRequestedSettings = this.getRequestedSettings.bind(this);
  }

  makeFromJson(o: any): Setting {
    return {
      id: o.id,
      group: o.group,
      name: o.name,
      setting_type: o.type,
      value_int: o.int,
      value_string: o.string
    };
  }

  makeRequestedFromJson(o: any): RequestedSetting {
    return o as RequestedSetting;
  }

  async getRequestedSettings(): Promise<List<RequestedSetting>> {
    const url = this.makeSubUrl("requested");
    const requested = await getJson(url) as [];

    return List(requested.map(r => this.makeRequestedFromJson(r)));
  }

  async getAll(): Promise<List<Setting>> {
    const settings = await getJson(this.apiUrl) as [];

    return List(settings.map(s => this.makeFromJson(s)))
  }

  async storeSetting(group: string, name: string, valueType: string, value: number | string): Promise<void> {
    const payload = {
      value_string: "",
      value_int: 0,
      value_type: valueType
    }
    if (typeof value === "string") {
      payload.value_string = value;
    } else {
      payload.value_int = value;
    }

    const url = this.makeSubUrl(group, name);
    await postJson(url, payload);
  }

  getSettingValue(setting: Setting | undefined): number | string | undefined {
    if (setting === undefined) {
      return undefined;
    }

    if (setting.setting_type === "string") {
      return setting.value_string;
    }
    return setting.value_int;
  }
}

export default new SettingsController();
