//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import {Account} from "../../types/accounts";
import React, {useEffect, useState} from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Iban from "../helpers/Iban";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import {CardHeader} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import FavoriteIcon from '@material-ui/icons/Favorite';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from "@material-ui/core/Tooltip";
import HomeChart from "./HomeChart";
import clsx from "clsx";
import AccountBalanceDisplay from "./AccountBalanceDisplay";
import useAccountMutation from "../../hooks/useAccountMutation";
import useSystemInfo from "../../hooks/useSystemInfo";
import useAccountBalanceHistory from "../../hooks/useAccountBalanceHistory";
import {generateColor, getTextColorTypeForBackgroundColor} from "../../helpers";
import {TextColorType} from "../../constants";

interface AccountProps {
  account: Account
}

const useStyles = makeStyles((theme) => ({
  details: {
    marginLeft: 'auto !important',
    marginRight: theme.spacing(1)
  },
  card: {
    maxWidth: '23rem',
    marginTop: theme.spacing(2),
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    }
  },
  chartContainer: {
    position: 'relative',
    height: theme.spacing(8),
  },
  balanceContainer: {
    position: 'absolute',
    margin: '0 auto',
    left: 0,
    right: 0,
    bottom: theme.spacing(2),
    textAlign: 'center',
    pointerEvents: 'none',
  },
  textLight: {
    color: theme.palette.text.primary,
  },
  textDark: {
    color: theme.palette.background.default,
  },
  chartContainerNoHistory: {
    backgroundImage: 'linear-gradient(#fff, #faf3ec, #e8c1a0);',
  },
}));

const AccountDisplay: React.FC<AccountProps> = ({ account }) => {
  const classes = useStyles();
  const accountMutation = useAccountMutation();
  const {data: info, isFetching: isFetchingSystemInfo} = useSystemInfo();
  const {data: balanceHistory} = useAccountBalanceHistory(account.id);
  const [backgroundColor, setBackgroundColor] = useState(generateColor(account.name));

  const getClassname = () => {
    const t = getTextColorTypeForBackgroundColor(backgroundColor);

    if (t == TextColorType.Light) {
      return classes.textLight;
    }
    return classes.textDark;
  }

  const [textClassname, setTextClassname] = useState('');

  useEffect(() => {
    setBackgroundColor(generateColor(account.name));
  }, [setBackgroundColor, account]);

  useEffect(() => {
    setTextClassname(getClassname());
  }, [backgroundColor, setTextClassname]);

  const HideIcon = account.hidden ? VisibilityIcon : VisibilityOffIcon;

  if (isFetchingSystemInfo || info === undefined) {
    return null;
  }

  const chartContainerClasses = clsx(classes.chartContainer, {
      [classes.chartContainerNoHistory]: info.influxEnabled && balanceHistory && balanceHistory.length <= 1
  });

  const flipBooleanFlags = (attr: "favorite" | "hidden") => () => {
    accountMutation.mutate({
      id: account.id,
      [attr]: !account[attr]
    });
  }

  return <Card className={classes.card}>
    <CardHeader
      avatar={
        <Avatar
          style={{
            backgroundColor,
          }}
          className={textClassname}
        >
          {account.name.charAt(0).toUpperCase()}
        </Avatar>
      }
      title={account.name}
      subheader={<Iban>{account.iban}</Iban>}
    />
    <CardContent>
      <div className={chartContainerClasses}>
        <HomeChart data={balanceHistory} />
        <div className={classes.balanceContainer}>
          <AccountBalanceDisplay balance={account.balance} />
        </div>
      </div>
    </CardContent>
    <CardActions>
      <Tooltip title={account.favorite ? "Remove favorite" : "Add favorite"}>
        <IconButton aria-label="add to favorites" onClick={flipBooleanFlags("favorite")}>
          <FavoriteIcon color={account.favorite ? "secondary" : "inherit"} />
        </IconButton>
      </Tooltip>
      <Tooltip title={account.hidden ? "Show account" : "Hide account"}>
        <IconButton aria-label="set account hidden" onClick={flipBooleanFlags("hidden")}>
          <HideIcon/>
        </IconButton>
      </Tooltip>
      <Button className={classes.details} size="small" component={Link} to={`/account/${account.id}`}>
        Details
      </Button>
    </CardActions>
  </Card>;
}

export default AccountDisplay;