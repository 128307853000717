//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React, {useState} from 'react';
import {List, Set} from "immutable";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";

import DateRenderer from "./helpers/DateRenderer";
import Balance from "./helpers/Balance";
import TransactionTags from "./TransactionTags";

import {Tag} from "../types/tags";
import {Transaction} from "../types/transactions";
import useTransactionTagsByAccount from "../hooks/useTransactionTagsByAccount";
import {Account} from "../types/accounts";

interface TransactionTableBodyProps {
  account: Account
  transactions: List<Transaction>
  onSelectRow: (transactionId: number) => void
  selectedRows: Set<number>
}

const TransactionTableBody: React.FC<TransactionTableBodyProps> = ({account, transactions, onSelectRow, selectedRows}) => {
  const tags = useTransactionTagsByAccount(account);

  if (tags === undefined) {
    return null;
  }

  const t = transactions.map(t => (
    <TableRow
      key={t.subject + t.date.toISOString()}
      onClick={() => onSelectRow(t.id)}
      selected={selectedRows.includes(t.id)}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={selectedRows.includes(t.id)}
        />
      </TableCell>
      <TableCell scope="row">{t.subject}</TableCell>
      <TableCell>{t.recipient}</TableCell>
      <TableCell align="right"><DateRenderer>{t.date}</DateRenderer></TableCell>
      <TableCell align="right"><Balance>{t.amount}</Balance></TableCell>
      <TableCell align="right">
        <TransactionTags account={account} transaction={t} tags={tags.get(t.id.toString()) || List<Tag>()} />
      </TableCell>
    </TableRow>
  )).toArray();

  return <>
    {t}
  </>;
}

export default TransactionTableBody;
