//    Copyright 2021 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import {Map} from "immutable";

import useSettings from "./useSettings";

import {ByGroupAndName} from "../types/settings";

export function useSettingsByGroupAndName() {
  const {data, isFetching} = useSettings();

  let result: ByGroupAndName = Map();

  if (isFetching || !data) {
    return undefined;
  }

  for (const s of data) {
    const current = result.getIn([s.group], Map());
    result = result.set(s.group, current.set(s.name, s));
  }

  return result;
}