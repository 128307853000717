//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import BaseController from "./BaseController";
import {User, UserData} from "../../types/user";
import {getJson, postJson} from "../../helpers";

class UserDataController extends BaseController<UserData> {
  constructor() {
    super("user");

    this.getLoginData = this.getLoginData.bind(this);
    this.updateApiKey = this.updateApiKey.bind(this);
  }

  makeFromJson(o: any): UserData {
    return {
      id: -1,
      loggedIn: o.logged_in,
      user: o.user,
    };
  }

  async getLoginData(): Promise<UserData> {
    const result = await getJson(this.makeSubUrl("login"));

    return this.makeFromJson(result);
  }

  async updateApiKey(): Promise<User> {
    return await postJson(this.makeSubUrl("apikey"));
  }
}

export default new UserDataController();
