//    Copyright 2021 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React from "react";

import {Link} from "react-router-dom";

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";

import {SidebarEntry} from "../../types/sidebar";
import Hidden from "@material-ui/core/Hidden";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useSidebar from "../../state/useSidebar";

interface SidebarLinkSectionProps {
  links: SidebarEntry[]
}

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const SidebarLinkSection: React.FC<SidebarLinkSectionProps> = ({links}) => {
  const setShowSidebar = useSidebar(state => state.setShowSidebar);
  const classes = useStyles();

  const collapseSidebar = () => {
    setShowSidebar(false);
  }

  return <List>
    {links.map((entry, id) => {
      let component;
      let key;
      if (entry.type === "divider") {
        key = `diviver-${id}`;
        component = <Divider key={key} className={classes.divider}/>;
      } else {
        const {target, Icon, label, disableRouting} = entry;

        key = target;
        const entryComponent = disableRouting === true ? "a" : Link;

        component = <ListItem
          button component={entryComponent} href={target} to={target} key={key} onClick={collapseSidebar}
        >
          <ListItemIcon><Icon/></ListItemIcon>
          <ListItemText primary={label}/>
        </ListItem>;
      }

      if (entry.hidden !== undefined) {
        return <Hidden only={entry.hidden} key={key}>
          {component}
        </Hidden>;
      }
      return component;
    })}
  </List>
}

export default SidebarLinkSection;
