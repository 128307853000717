//    Copyright 2021 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import {useState, useEffect} from "react";

import {Account} from "../types/accounts";
import {useQuery} from "react-query";
import {QUERY_KEYS} from "../constants";
import {Tag, TagsByTransaction, TransactionId} from "../types/tags";
import {List, Map} from "immutable";

export default function useTransactionTagsByAccount(account: Account) {
  // initialize empty, fill using mutation
  const {data} = useQuery<TagsByTransaction>(
    [QUERY_KEYS.tags, account.id],
    () => Map<TransactionId, List<Tag>>());

  return data;
}
