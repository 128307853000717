//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import {Tag} from "../../types/tags";

interface TagOverviewRowsProps {
  tags: Tag[]
  onEditTag: (tag: Tag) => void
}

const TagOverviewRows: React.FC<TagOverviewRowsProps> = ({tags, onEditTag}) => {
  const rows = tags.map(tag => {
    return (
      <TableRow key={tag.name}>
        <TableCell scope="row">{tag.name}</TableCell>
        <TableCell>{tag.description}</TableCell>
        <TableCell>
          <IconButton size="small" onClick={event => {
            event.stopPropagation();
            onEditTag(tag);
          }}>
            <EditIcon fontSize="inherit" />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  });

  return <>
    {rows}
  </>
}

export default TagOverviewRows;
