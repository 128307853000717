//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React, {useEffect, useState} from 'react';

import {Datum, Serie, ResponsiveLine} from "@nivo/line";
import AccountBalanceDisplay from "./AccountBalanceDisplay";

const theme = {
  "axis": {
    "domain": {
      "line": {
        "strokeWidth": 0
      }
    },
    "ticks": {
      "line": {
        "strokeWidth": 0
      }
    }
  },
  "grid": {
    "line": {
      "strokeWidth": 0
    }
  }
}

interface HomeChartProps {
  data?: Datum[]
}

function makeSerie(data?: Datum[]): Serie[] {
  return [{
    id: "balance",
    color: "#EE4540",
    data: data || [],
  }];
}

const HomeChart: React.FC<HomeChartProps> = ({data}) => {
  const [serie, setSerie] = useState<Serie[]>([]);

  useEffect(() => {
    setSerie(makeSerie(data));
  }, [data]);

  if (data === undefined || data.length <= 1) {
    return null;
  }

  return <ResponsiveLine
    theme={theme}
    data={serie}
    useMesh={true}
    tooltip={({point}) => <AccountBalanceDisplay balance={Number(point.data.y)} />}
    enableArea={true}
    enableGridX={false}
    enableGridY={false}
    margin={{
      top: 7,
      bottom: 7,
      left: 7,
      right: 7
    }}
    yScale={{
      type: 'linear',
      min: 'auto'
    }}
  />;
}

export default HomeChart;
