//    Copyright 2020 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React, {useEffect, useState} from 'react';
import {List} from 'immutable';

import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from "@material-ui/core/Tooltip";

import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';

import {createStyles, makeStyles} from '@material-ui/core/styles';

import {Transaction} from '../../types/transactions';
import {Tag, TagsUpdatedFunc} from "../../types/tags";
import useAllTags from "../../hooks/useAllTags";


interface TransactionTagsProps {
  transaction: Transaction
  tags: List<Tag>
  tagsUpdated: TagsUpdatedFunc
}

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    autoComplete: {
      flexGrow: 1
    }
  }),
);

function renderTag(tag: Tag, additionalProps: object = {}) {
  return <Chip
    {...additionalProps}
    key={tag.name}
    size="small"
    variant="outlined"
    label={tag.name}
  />;
}

const TransactionTags: React.FC<TransactionTagsProps> = ({ tags, transaction, tagsUpdated }) => {
  const allTags = useAllTags();
  const [isEditMode, setEditMode] = useState(false);
  const [inputTags, setInputTags] = useState<Tag[]>([]);
  const classes = useStyles();

  useEffect(() => {
    setInputTags(tags.toArray());
  }, [tags, setInputTags]);

  const chips = isEditMode ? undefined : tags.map(tag => renderTag(tag));

  const addChip = isEditMode ? <Autocomplete
    size="small"
    multiple
    onChange={(event, value) => tagsUpdated(transaction, List(value))}
    value={inputTags}
    openOnFocus
    className={classes.autoComplete}
    options={allTags}
    getOptionLabel={t => t.name}
    onClick={event => event.stopPropagation()}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Add tag"
        variant="outlined"
        placeholder="Tags"
        size="small"
        margin="dense"
        onClick={event => event.stopPropagation()}
      />
    )}
    renderTags={(tags, getTagsProps) => (
      tags.map((tag, index) => {
        const props = getTagsProps({index});
        return renderTag(tag, props);
      })
    )}
  /> : undefined;

  const Icon = isEditMode ? CheckIcon : EditIcon;
  const editButtonToggle = <Tooltip title={isEditMode ? "Finish tags editing" : "Edit tags"}>
    <IconButton size="small" onClick={event => {
      event.stopPropagation();
      setEditMode(!isEditMode);
    }}>
      <Icon fontSize="inherit" />
    </IconButton>
  </Tooltip>;

  return <div className={classes.wrapper}>
    {chips}
    {addChip}
    {editButtonToggle}
  </div>;
}

export default TransactionTags;
