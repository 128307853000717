//    Copyright 2021 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import {useQuery} from "react-query";
import {QUERY_KEYS} from "../constants";
import TagController from "../classes/controller/TagController";
import {useEffect, useState} from "react";
import {Tag} from "../types/tags";

export default function useAllTags() {
  const [result, setResult] = useState<Tag[]>([]);

  const {data} = useQuery(QUERY_KEYS.allTags, TagController.getAll, {
    staleTime: Infinity
  });

  useEffect(() => {
    if (data) {
      setResult(data.toArray());
    }
  }, [data, setResult]);

  return result;
}
