//    Copyright 2021 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React, {useState} from "react";
import Immutable from "immutable";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import EditIcon from '@material-ui/icons/Edit';

import {Transaction} from "../../types/transactions";
import {Tag, TagsByTransaction, TagsUpdatedFunc} from "../../types/tags";
import {formatAmount, maxLength} from "../../helpers";
import TransactionTagEditDialog from "./TransactionTagEditDialog";

function getColor(amount: number): "error" | "textSecondary" {
  if (amount < 0) {
    return "error";
  }
  return "textSecondary";
}

// TODO memoize!
function getTagsForTransaction(allTags: TagsByTransaction, transaction: Transaction | undefined): Tag[] {
  if (transaction === undefined) {
    return [];
  }
  const tags = allTags.get(transaction.id.toString());

  return tags?.toArray() || [];
}

interface TransactionListingProps {
  transactions: Immutable.List<Transaction>
  tags: TagsByTransaction,
  tagsUpdated: TagsUpdatedFunc,
}

const TransactionListing: React.FC<TransactionListingProps> = ({transactions, tags, tagsUpdated}) => {
  const [transactionToEdit, setTransactionToEdit] = useState<Transaction|undefined>(undefined);

  return <>
    <TransactionTagEditDialog
      onClose={() => setTransactionToEdit(undefined)}
      transaction={transactionToEdit}
      tags={getTagsForTransaction(tags, transactionToEdit)}
      tagsUpdated={tagsUpdated}
    />
    <List dense={true}>
      {transactions.map(t => (
        <ListItem>
          <ListItemText
            primary={maxLength(t.subject)}
            secondary={
              <>
                <Typography
                  component="span"
                  variant="body2"
                  style={{display: "inline"}}
                  color="textSecondary"
                >
                  {t.recipient}{t.recipient.length ? " — " : ""}
                </Typography>
                {formatAmount(t.amount)}
              </>
            }
            secondaryTypographyProps={{color: getColor(t.amount)}}
          />
          <ListItemSecondaryAction>
            <IconButton size="small" onClick={() => setTransactionToEdit(t)}>
              <EditIcon/>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  </>;
}

export default TransactionListing;
