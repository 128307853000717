//    Copyright 2019 Jannis Fink
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        http://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.

import React from 'react';

import Button from '@material-ui/core/Button';

import { Switch, Route } from 'react-router-dom';

import Home from './home/Home';
import Profile from './Profile';
import AccountDetails from './AccountDetails';
import Info from "./Info";
import Tags from "./tags/Tags";
import Settings from "./settings/Settings";

import './App.scss';
import Toolbar from "@material-ui/core/Toolbar";
import useUserData from "../hooks/useUserData";


const ExovApp: React.FC = () => {
  return <Switch>
    <Route exact path="/" component={Home} />
    <Route path="/info" component={Info} />
    <Route path="/tags" component={Tags} />
    <Route path="/user/profile" component={Profile} />
    <Route path="/account/:accountId" component={AccountDetails} />
    <Route path="/settings" component={Settings} />
  </Switch>;
}

const LoginApp: React.FC = () => {
  return <Button variant="contained" color="primary" href="/login/nextcloud">
    Login
  </Button>
}

const App: React.FC = () => {
  const {data: user, isFetching: isUserFetching} = useUserData();

  if (isUserFetching) {
    return null;
  }

  const PageComponent = user?.loggedIn ? ExovApp : LoginApp;

  return <>
    <Toolbar />
    <PageComponent />
  </>;
}

export default App;
